import * as ToggleGroup from '@radix-ui/react-toggle-group';
import React from 'react';

export interface SwitchIconProps {
  icon: JSX.Element;
  position?: 'left' | 'right' | 'top' | 'bottom';
}

export type SwitcherOptionType = {
  text?: string;
  subText?: string;
  value: string;
  icon?: SwitchIconProps;
};

export enum SwitcherVariants {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export interface SwitcherProps {
  options: SwitcherOptionType[];
  variant?: SwitcherVariants;
  value: string;
  onChange: (option: string) => void;
  size?: 'small' | 'large' | 'content';
  'aria-label': string;
}

export const Switcher: React.FC<SwitcherProps> = ({
  options,
  value,
  onChange,
  variant = SwitcherVariants.Primary,
  size = 'large',
  ...props
}) => {
  let variantClassName =
    'border border-brandMidnight text-brandMidnight hover:text-brandMidnight-hover bg-brandElectric hover:bg-brandElectric-hover';
  if (variant === SwitcherVariants.Secondary) {
    variantClassName =
      'text-neutral-0 bg-brandMidnight hover:bg-brandMidnight-hover';
  }
  let containerSizeClass = '';
  if (size !== 'content') {
    containerSizeClass =
      size === 'large'
        ? 'text-body1Medium h-[48px]'
        : 'text-body2Medium h-[46px]';
  }

  return (
    <ToggleGroup.Root
      type="single"
      className={`flex flex-grow rounded-[6px] border-[1px] border-neutral-300 bg-neutral-100 py-[3px] ${containerSizeClass}`}
      {...props}
    >
      {options.map((option, index) => {
        const checked = option.value === value;

        const icon = option?.icon?.icon || null;

        return (
          <ToggleGroup.Item
            key={`${option.value}-${index}`}
            className="group flex flex-1 cursor-pointer items-center justify-center px-xs"
            value={`${option.value}-${index}`}
          >
            <div
              className={`${
                option?.icon &&
                (option.icon.position === 'top' ||
                  option.icon?.position === 'bottom')
                  ? 'flex flex-col'
                  : 'flex'
              } border-box relative h-full w-full items-center justify-center gap-s whitespace-nowrap rounded-[6px] px-s py-[13px] ${
                checked ? variantClassName : 'hover:bg-neutral-200'
              } `}
              onClick={() => onChange(option.value)}
            >
              {option.icon &&
                (option.icon.position === 'left' ||
                  option.icon.position === 'top') &&
                icon}
              {option.text && (
                <div>
                  {option.text}
                  {option.subText && <div>{option.subText}</div>}
                </div>
              )}
              {option.icon &&
                option.icon.position !== 'left' &&
                option.icon.position !== 'top' &&
                icon}
            </div>
          </ToggleGroup.Item>
        );
      })}
    </ToggleGroup.Root>
  );
};
